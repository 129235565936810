import { toInteger } from '../../../utils/lodash-min';

export interface InternalMotive {
    id: string;
    url: string;
}
export class InternalMotives {
    private static get Rand160x600Index() {
        return toInteger(Math.random() * InternalMotives.motive160.length);
    }

    private static get Rand728x90Index() {
        return toInteger(Math.random() * InternalMotives.motive728.length);
    }

    private static motive160: InternalMotive[] = [
        {
            id: '160-1',
            url: 'img/xconvert/motive/im-v-1.png',
        },
        {
            id: '160-2',
            url: 'img/xconvert/motive/im-v-2.png',
        },
        {
            id: '160-3',
            url: 'img/xconvert/motive/im-v-3.png',
        }, {
            id: '160-4',
            url: 'img/xconvert/motive/im-v-4.png',
        }, {
            id: '160-5',
            url: 'img/xconvert/motive/im-v-5.png',
        }, {
            id: '160-6',
            url: 'img/xconvert/motive/im-v-6.png',
        }, {
            id: '160-7',
            url: 'img/xconvert/motive/im-v-7.png',
        }, {
            id: '160-8',
            url: 'img/xconvert/motive/im-v-8.png',
        },
    ];
    private static motive728: InternalMotive[] = [
        {
            id: '728-1',
            url: 'img/xconvert/motive/im-h-1.png',
        }, {
            id: '728-2',
            url: 'img/xconvert/motive/im-h-2.png',
        }, {
            id: '728-3',
            url: 'img/xconvert/motive/im-h-3.png',
        }, {
            id: '728-4',
            url: 'img/xconvert/motive/im-h-4.png',
        }, {
            id: '728-5',
            url: 'img/xconvert/motive/im-h-5.png',
        }, {
            id: '728-6',
            url: 'img/xconvert/motive/im-h-6.png',
        }, {
            id: '728-7',
            url: 'img/xconvert/motive/im-h-7.png',
        }, {
            id: '728-8',
            url: 'img/xconvert/motive/im-h-8.png',
        }, {
            id: '728-9',
            url: 'img/xconvert/motive/im-h-9.png',
        }, {
            id: '728-10',
            url: 'img/xconvert/motive/im-h-10.png',
        }, {
            id: '728-11',
            url: 'img/xconvert/motive/im-h-11.png',
        }, {
            id: '728-12',
            url: 'img/xconvert/motive/im-h-12.png',
        }, {
            id: '728-13',
            url: 'img/xconvert/motive/im-h-13.png',
        }, {
            id: '728-14',
            url: 'img/xconvert/motive/im-h-14.png',
        },
    ];

    public static getNext160Motive() {
        return InternalMotives.motive160[InternalMotives.Rand160x600Index];
    }

    public static getNext728Motive() {
        return InternalMotives.motive728[InternalMotives.Rand728x90Index];
    }
}
