import { Component, createRef } from 'react';

import { IOC_TYPES } from '../../../context/ioc.types';
import { lazyInject } from '../../../context/module';
import { Logger } from '../../../models/logger';
import { ApiConfig } from '../../../services/api-config';
import { LightHttp } from '../../../services/light-http.service';
import { MotivesService } from '../../../services/motives.service';
import { WindowService } from '../../../services/window.service';
import { MotiveConfig } from '../../../utils/request.utils';
import { StringUtils } from '../../../utils/string-utils';
import { untilDestroyed } from '../../../utils/until-destroyed';
import { isNaN, isNumber, parseInt, toString } from '../../../utils/lodash-min';
import styles2 from './internal-motive.component.module.scss';
import { InternalMotive, InternalMotives } from './internal-motives';
import styles1 from './motive.component.module.scss';

const styles = Object.assign({}, styles1, styles2);


const defaultOptions = {
    showAds: true,
}

interface InternalMotiveComponentProps {
    internalMotiveId?: string;
    containerWidth?: string;
    containerHeight?: string;
    adWidth?: string;
    adHeight?: string;
    showAds?: boolean;
}

export default class InternalMotiveComponent extends Component<InternalMotiveComponentProps> {
    private readonly logger = Logger.getLogger('InternalMotiveComponent');

    mergedOptions: InternalMotiveComponentProps = {};

    internalMotive: InternalMotive;

    currentMotiveSlotIsEnabled = false;
    motiveType = null;
    uuid = 'x' + (StringUtils.uuid() as string).replace(/\-/g, '');
    initialized = false;
    private effectiveMotives: MotiveConfig;

    motiveLocationDisplayedAfterUserInteration = false;
    userInteractedWithPage = false;

    parent = createRef<HTMLDivElement>();
    container = createRef<HTMLDivElement>();

    private containerElemRef1 = createRef<HTMLDivElement>();
    private containerElemRef2 = createRef<HTMLDivElement>();
    private get containerElemRef() {
        return this.containerElemRef1.current || this.containerElemRef2.current;
    };

    get internalMotivesEnabled() {
        return this.effectiveMotives && this.effectiveMotives.internalMotivesEnabled;
    }

    @lazyInject(IOC_TYPES.LightHttp) private http: LightHttp;
    @lazyInject(IOC_TYPES.ApiConfig) private apiConfig: ApiConfig;
    @lazyInject(IOC_TYPES.MotivesService) private motivesService: MotivesService;
    @lazyInject(IOC_TYPES.WindowService) private windowService: WindowService;

    constructor(props: InternalMotiveComponentProps) {
        super(props);
        this.effectiveMotives = this.motivesService.currentUserMotiveConfig;
    }

    componentDidMount() {
        this.initInternalMotive();
        this.windowService.userEngaged.subscribe({
            next: () => {
                this.userInteractedWithPage = true;
                this.updateView();
            }
        });

        this.motivesService.onEffectiveMotiveSettingsChange.pipe(untilDestroyed(this)).subscribe({
            next: (motives) => {
                this.effectiveMotives = motives;
                // this.setUpMotiveRotation();
                this.updateView();
            },
            error: (error) => this.logger.error(error),
        });

        // if (this.container && this.container.nativeElement) {
        //     /* tslint:disable */
        //     const flags = RendererStyleFlags2.DashCase | RendererStyleFlags2.Important;
        //     // this.renderer.setStyle(this.container.nativeElement, 'margin', 'auto');
        //     // this.renderer.setStyle(this.parent.nativeElement, 'width', this.containerWidth, flags);
        //     // this.renderer.setStyle(this.parent.nativeElement, 'height', this.containerHeight, flags);
        // }
        this.initialized = true;
    }

    updateView() {
        if (this.effectiveMotives) {
            this.motiveType = this.effectiveMotives && this.effectiveMotives.externalMotiveType;
        }

        if (this.mergedOptions.showAds) {
            this.currentMotiveSlotIsEnabled = true;
            if (this.currentMotiveSlotIsEnabled) {
                this.updateWidth();
            }
        }

        this.forceUpdate();
    }

    private updateWidth() {
        const widthAsNumber = parseInt(this.mergedOptions.adWidth);
        if (isNumber(widthAsNumber) && !isNaN(widthAsNumber)) {
            try {
                if (window && (window.innerWidth < widthAsNumber)) {
                    this.mergedOptions.adWidth = toString(window.innerWidth) + 'px';
                }
            } catch {
                // prevent throw in SSR mode
            }
        }
    }


    initInternalMotive() {
        try {
            const widthAsNumber = parseInt(this.mergedOptions.adWidth);
            if (isNumber(widthAsNumber) && !isNaN(widthAsNumber)) {
                if (window.innerWidth < widthAsNumber) {
                    this.mergedOptions.adWidth = toString(window.innerWidth) + 'px';
                }
            }

            if (this.mergedOptions.internalMotiveId.indexOf('728') > -1) {
                const motive = InternalMotives.getNext728Motive();
                this.internalMotive = motive;
            } else if (this.mergedOptions.internalMotiveId.indexOf('160') > -1) {
                const motive = InternalMotives.getNext160Motive();
                this.internalMotive = motive;
            }

            if (this.container && this.container.current) {
                this.container.current.style.margin = 'auto';
                this.parent.current.style.width = `${this.mergedOptions.containerWidth} !important`;
            }
            if (this.mergedOptions.showAds && this.internalMotivesEnabled) {
                setTimeout(() => {
                    const viewWidth = window.innerWidth;
                    if (this.shouldFitToContainer()) {
                        const rect = this.containerElemRef.getBoundingClientRect();
                        // this.renderer.setStyle(this.containerElemRef.current, 'height', '90px');
                        this.containerElemRef.style.width = `${rect.width}px`;
                        this.parent.current.style.width = `${rect.width}px`;
                    }

                }, 400);
            }
        } catch (e) {
            this.logger.error(e);
        }
    }

    handleMotiveClick() {
        this.http.post(this.apiConfig.getMotiveStatEndpoint(), this.internalMotive).subscribe(
            (data) => {
                // 
            },
            err => {
                this.logger.error(err);
            },
        );
    }

    shouldFitToContainer() {
        return this.mergedOptions.containerWidth === '100%';
    }

    toggleGContent() {
        this.mergedOptions.showAds = !this.mergedOptions.showAds;
    }

    showGContent() {
        this.mergedOptions.showAds = true;
    }

    getInternalMotive() {
        if (this.mergedOptions.showAds && this.internalMotivesEnabled) {
            return <div ref={this.containerElemRef1} className="m-container" >
                <div className="gclose" onClick={() => this.toggleGContent()
                } > X</div >
                <div
                    ref={this.container}
                    onClick={() => this.handleMotiveClick()}
                    className="gcontainer cursor-pointer"
                    style={{ width: `${this.mergedOptions.adWidth}` }}
                >
                    {
                        this.internalMotive && <img src={this.internalMotive.url} />
                    }
                </div >
            </div >;
        } else {
            return <span ref={this.containerElemRef2}></span>;
        }
    }

    render() {
        this.mergedOptions = Object.assign({}, defaultOptions, this.props);
        return this.getInternalMotive();
    }
}
